<template>
	<banner-data
		:form-import="form"
		:valid-user-types="validUserTypes"
		:valid-countries="validCountries"
		:valid-device-types="validDeviceTypes"
		:priorities="priorities"
		:loading="loading"
		:errors-import="errors"
		@requestData="requestCreateBanner" />
</template>
<script>
import {
	USER_TYPES as validUserTypes,
	DEVICE_TYPES as validDeviceTypes,
	PRIORITIES as priorities,
} from '@/settings/Banners';
import { VALID_COUNTRIES as validCountries } from '@/settings/Country';
import { Banners as messages } from '@/translations';
import Banner from '@/util/Banner';
import BannerData from './components/BannerData';

export default {
	name: 'NewBanner',
	messages,
	components: { BannerData },
	data() {
		return {
			banner: new Banner(),
			alert: new this.$Alert(),
			loading: false,
			form: {
				title: '',
				image: '',
				mobile_image: '',
				url: '',
				user_types: 'distributor',
				countries: '',
				priority: 'major',
				device_type: 'backoffice',
				start_date: '',
				end_date: '',
				show_in_dashboard: false,
			},
			validUserTypes,
			validCountries,
			priorities,
			validDeviceTypes,
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.banner.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.banner.data.errors.errors = newErrors;
			},
		},
	},
	methods: {
		requestCreateBanner(payload) {
			this.loading = true;
			this.banner.createBanner(payload).then(() => {
				this.alert.toast('success', this.translate('banner_created'));
				this.$router.push({ name: 'Banners' });
			}).catch((err) => {
				this.errors = { ...err.errors };
			}).finally(() => { this.loading = false; });
		},
	},
};
</script>
